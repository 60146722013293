import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Header } from "components";
import { Layout } from "containers";

// TODO: add 404 page content

const PageNotFound = ({
  data: {
    config: { header, seo },
  },
}) => {
  const layoutProps = {
    seoProps: seo,
  };

  const headerProps = {
    buttons: [
      {
        label: "Find a job",
        path: "/",
      },
      {
        label: "Post a job",
        path: "/jobs/new/",
      },
    ],
    ...header,
  };

  return (
    <Layout {...layoutProps}>
      <Header {...headerProps} />
    </Layout>
  );
};

PageNotFound.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        context: PropTypes.string,
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
};

export const notFoundPageQuery = graphql`
  query NotFoundPageQuery {
    config: pagesYaml(id: { eq: "PageNotFound" }) {
      header {
        context
        heading
      }
      id
      seo {
        title
      }
    }
  }
`;

export default PageNotFound;
